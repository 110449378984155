<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		tooltipMsg="manualforportal3316"
		:showActionDelete="!!detailEntityId"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #header>
			{{ $t('detail') }}
			<label>{{ $t('id') }}&nbsp;{{ model.PratingId }}</label>
		</template>
	</detail-page>
</template>
<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceEnums from '../../services/service/enums.service';
import serviceCommon from '../../services/service/common.service';

import { model, fields } from './portal-rating.form-data';

export default {
	name: 'PortalRatingsDetail',

	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'PortalRating',
			routeParam: 'PratingId',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	methods: {
		loadResources() {
			this.updateSchemaFields({
				CountryId: {
					values: this.$store.state.country.data,
				},
				PratingPersonType: {
					values: serviceEnums.getEnumForVFG('personType'),
				},
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.PratingId, this.$modal, this.deleteModel);
		},
	},
};
</script>

import vueFormGenerator from 'vue-form-generator';
import { formatter } from '../../services/helpers/formatter';

export const model = {
	PratingId: 0,
	PratingDatetime: null,
	PratingPersonType: null,
	CountryId: null,
	PratingName: '',
	PratingEmail: '',
	PratingPhone: '',
	PratingVisible: 0,
	PratingTop: 0,
	PratingText: '',
};

export const fields = [
	{
		fields: [
			{
				type: 'single-datepicker',
				model: 'PratingDatetime',
				required: true,
				autoselect: true,
				language: 'sk',
				showtime: true,
				validator: vueFormGenerator.validators.date,
				placeholder: 'Datum pridania',
				i18n: {
					label: 'created:',
				},
			},
			{
				type: 'select',
				required: true,
				model: 'PratingPersonType',
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'id',
					name: 'name',
				},
				i18n: {
					label: 'type:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'select',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'Id',
					name: 'Name',
				},
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'input',
				inputType: 'text',
				min: 1,
				required: true,
				validator: vueFormGenerator.validators.string,
				model: 'PratingName',
				i18n: {
					label: 'name:',
				},
			},
			{
				type: 'input',
				inputType: 'email',
				model: 'PratingEmail',
				required: true,
				//hint: "Nápoveda",
				validator: vueFormGenerator.validators.email,
				i18n: {
					label: 'e-mail:',
				},
			},
			{
				type: 'input',
				inputType: 'tel',
				min: 1,
				required: true,
				validator: vueFormGenerator.validators.string,
				model: 'PratingPhone',
				i18n: {
					label: 'phone:',
				},
			},
			{
				type: 'switch',
				model: 'PratingVisible',
				readonly: false,
				featured: false,
				disabled: false,
				default: true,
				i18n: {
					label: 'displayed:',
					textOn: 'yes',
					textOff: 'no',
				},
			},
			{
				type: 'switch',
				model: 'PratingTop',
				multi: true,
				readonly: false,
				featured: false,
				disabled: false,
				default: true,
				i18n: {
					label: 'top:',
					textOn: 'activated',
					textOff: 'inactive',
				},
			},
			{
				type: 'textArea',
				model: 'PratingText',
				min: 1,
				rows: 8,
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'text:',
				},
			},
		],
	},
];
